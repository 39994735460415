import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { ILayoutConf, LayoutService } from 'app/shared/services/layout.service';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { PmsService } from 'app/shared/services/pms/pms.service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  productId: any;
  private dataSubscription: Subscription;
  tourCode: any;
  constructor(
    private api: PmsService,
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService,
    private router: Router
  ) {
    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url.split('/')[1] == 'cms') {
    //       this.layout.publishLayoutChange({ showSideNav: true });
    //     } else {
    //       this.layout.publishLayoutChange({ showSideNav: false });
    //     }
    //   }
    // });
    // this.dataSubscription = this.api.productId$.subscribe((data) => {
    //   this.productId = data;
    //   console.log('product Id', data);
    //   if (this.productId) {
    //     this.getTourDetails();
    //   }
    // });
    // if (this.productId) {
    //   this.getTourDetails();
    // }

    console.log(' this.dataSubscription ', this.dataSubscription);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
          let routes = menuItem.filter(
            (ae) => ae.url_slug == this.router.url.split('/')[1]
          );
          this.menuItems = routes.length > 0 ? routes[0].side_nav : [];
        });
      }
    });
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      let routes = menuItem.filter(
        (ae) => ae.url_slug == this.router.url.split('/')[1]
      );
      this.menuItems = routes.length > 0 ? routes[0].side_nav : [];
      // if (!this.tourCode) {
      //   this.menuItems = this.menuItems.map((item) => {
      //     if (item.name !== 'Website' && item.name !== 'Tour Details') {
      //       return { ...item, disabled: true }; // Add `disabled` property
      //     }
      //     return { ...item, disabled: false };
      //   });
      // }

      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === 'icon'
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;

    // if (this.router.url.split('/')[1] == 'cms') {
    //   this.layout.publishLayoutChange({ showSideNav: true });
    // } else {
    //   this.layout.publishLayoutChange({ showSideNav: false });
    // }
  }

  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }

  // getTourDetails() {
  //   this.api.getTourDetails(this.productId).subscribe((result) => {
  //     this.tourCode = result?.tour_code;
  //     console.log(result?.tour_code), console.log(result);
  //   });
  // }
}
